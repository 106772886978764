<template>
	<div id="AiChatBot">
		<header class="aichat-header">
			<h1 class="aichat-header-title"><i class="icon"></i>AI 챗봇</h1>
		</header>
		<div class="aichat-list">
			<ul class="aichat-msg" ref="aichatMsgUl">
				<li class="aichat-msg-intro">
					<div class="aichat-msg-intro__title"><i class="icon"></i>AI 챗봇</div>
					<p class="aichat-msg-intro__desc">
						<strong>온판 AI 챗봇</strong>은 대화로 답변을 찾아주는 서비스입니다.<br />
						궁금한 내용을 입력창에 문의해 주세요.
					</p>
				</li>
				<li class="aichat-msg-links">
					<button v-for="(item, idx) in btnInputs" :key="idx" type="button" class="aichat-msg-link" @click="makeSendText(item)">
						<i class="icon"></i>
						<p>{{ item }}</p>
					</button>
				</li>
				<template v-for="(item, idx) in inputs">
					<li class="aichat-msg-user">
						<span class="sr-only">{{ item.name }}</span>
						<div class="aichat-msg-item">
							<div class="aichat-msg-bubble">
								<p>{{ item.send }}</p>
							</div>
							<span class="aichat-msg-time">{{ item.sendTime }}</span>
						</div>
					</li>
					<li class="aichat-msg-bot" v-if="!item.prompt">
						<span class="sr-only">AI챗봇</span>
						<div class="aichat-msg-item">
							<div class="aichat-msg-bubble">
								<div class="aichat-msg-loading">
									<em><i></i><i></i><i></i></em>검색 및 결과 분석 중<em><i></i><i></i><i></i></em>
								</div>
							</div>
						</div>
					</li>
					<li class="aichat-msg-bot" v-if="item.prompt">
						<span class="sr-only">AI챗봇</span>
						<div class="aichat-msg-item">
							<div class="aichat-msg-bubble">
								<div class="aichat-msg-bubble__title"><i class="icon"></i>분석완료</div>
								<text-typing :content="item.prompt" @complete="complete" @scroll="scroll" />
								<div>
									<transition name="fade">
										<a v-if="item.link && item.linkName && item.complete" :href="item.link" class="text-link aichat-msg-button" target="_blank" title="새창열림"><i class="icon-aichat-link"></i>{{ item.linkName }}</a>
									</transition>
								</div>
							</div>
							<span class="aichat-msg-time">{{ item.promptTime }}</span>
						</div>
					</li>
				</template>
			</ul>
		</div>
		<div class="aichat-write">
			<fieldset>
				<legend class="sr-only">메시지 전송</legend>
				<input type="text" class="aichat-write-input" placeholder="궁금한 내용을 입력하세요" autofocus="" v-model="text" @keyup.enter="handleEnter" />
				<!-- <textarea 
					class="aichat-write-input textarea" 
					placeholder="궁금한 내용을 입력하세요" 
					autofocus="" 
					v-model="text" 
					@keyup.enter.prevent="handleEnter"
					style="padding-top: 10px; padding-bottom: 10px; line-height: 1.3; resize: none;"
					rows="4"
				/> -->
				<button type="submit" class="aichat-write-button" @click="clickSendBtn" ref="sendBtn">
					<i class="icon"></i>
					<span class="sr-only">전송</span>
				</button>
			</fieldset>
		</div>
	</div>
</template>

<script>
import TextTyping from '@/components/common/TextTyping';
import { ACT_GET_AI_CHAT_BOT_SEND_MSG } from '@/store/_act_consts';
import { getItem } from '../../../assets/js/utils';
export default {
	name: 'AiChatBot',
	components: { TextTyping },
	data: () => ({
		activeSend: true,
		text: '',
		btnInputs: ['소상공인 온라인 판로지원 사업에 대해서 설명해줘', '마케팅에 도움이 될 만한 지원사업을 알려줘', '상품 개선에 대해 교육, 컨설팅을 받고 싶어', '창업을 지원하는 지자체 사업에 대해 알고 싶어'],
		inputs: [],
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.initChatBot();
		});
	},
	methods: {
		initChatBot() {},
		async clickSendBtn() {
			if (!this.activeSend) return;
			this.activeSend = false;

			const send = this.text;
			this.text = '';
			const sendObj = {
				name: '사용자',
				send,
				sendTime: this.getCurrentTime(),
			};
			this.inputs = [...this.inputs, sendObj];
			this.scrollToBottom();

			let prompt = await this.getAiResultText(send);
			let tempPrompt = prompt;

			let linkName = '';
			let link = '';
			if (tempPrompt) {
				const linkNameMatch = tempPrompt.match(/\[([^\]]+)\][^\[]*$/);
				linkName = linkNameMatch ? linkNameMatch[1] : '';

				const linkMatch = tempPrompt.match(/\(([^)]+)\)[^()]*$/);
				link = linkMatch ? linkMatch[1] : '';

				if (linkName && link) {
					prompt = tempPrompt.split(/URL:/)[0];
				}
			}

			this.inputs = this.inputs.filter((item) => item.prompt);
			const input = {
				...sendObj,
				prompt,
				promptTime: this.getCurrentTime(),
				linkName,
				link,
				complete: false,
			};
			this.inputs = [...this.inputs, input];
		},
		scrollToBottom() {
			this.$nextTick(() => {
				const msgUlEle = this.$refs.aichatMsgUl;
				msgUlEle.scrollTo({
					// top: msgUlEle.scrollHeight,
					top: 999999,
					behavior: 'smooth',
					block: 'end',
				});
			});
		},
		async getAiResultText(text) {
			let result = '';
			await this.$store
				.dispatch(`aichatbot/${ACT_GET_AI_CHAT_BOT_SEND_MSG}`, { message: text })
				.then((res) => {
					if (200 == res.status.code) {
						const item = getItem(res);
						result = item.chatBotAiRes.results;
					}
				})
				.catch((err) => {
					console.error(err);
				});
			return result;
		},
		getCurrentTime() {
			const now = new Date();
			const hours = now.getHours().toString().padStart(2, '0');
			const minutes = now.getMinutes().toString().padStart(2, '0');
			const currentTime = `${hours}:${minutes}`;
			return currentTime;
		},
		makeSendText(text) {
			if (!this.activeSend) return;
			this.text = text;
			this.$refs.sendBtn.click();
		},
		complete() {
			this.activeSend = true;
			// 배열의 가장 마지막 인덱스 값을 구하는 방법
			this.inputs[this.inputs.length - 1].complete = true;
		},
		scroll() {
			this.scrollToBottom();
		},
		handleEnter() {
			if (!this.activeSend) return;
			this.$refs.sendBtn.click();
		},
	},
};
</script>
<style scoped>
.aichat-msg {
	overflow-y: auto;
	height: 100%;
	padding: 20px;
}
.textarea {
	width: 100%;
	box-sizing: border-box;
	font-size: 16px;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
	opacity: 0;
}
</style>
